<template>
  <div>
    <el-button type="primary" @click="$refs.edit.open(0)" style="margin-top: 10px;margin-bottom: 10px">
      {{$t("common.add")}}
    </el-button>
    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      border>
      <table-column prop="code" :label="$t('elevator.code')" width="100px">
      </table-column>
      <table-column v-if="$i18n.isCn" prop="reason" label="异常原因" width="300px"></table-column>
      <table-column v-if="$i18n.isEn" prop="reasonEn" label="Reason" width="300px"></table-column>
      <table-column v-if="$i18n.isIt" prop="reasonIt" label="Motivo" width="300px"></table-column>

      <table-column v-if="$i18n.isCn" prop="details" label="异常详情"></table-column>
      <table-column v-if="$i18n.isEn" prop="detailsEn" label="Details"></table-column>
      <table-column v-if="$i18n.isIt" prop="detailsIt" label="Dettagli dell'eccezione"></table-column>

      <table-column :label="$t('elevatorNetApply.operate')" align="center" width="200px" :tooltip="false">
        <template #default="scope">
          <el-button type="primary" @click="$refs.edit.open(scope.row.id)">{{$t("common.edit")}}</el-button>
          <el-button type="danger" @click="handleDelete(scope.row)">{{$t("common.delete")}}</el-button>
        </template>
      </table-column>
    </el-table>
    <eocd-exception-edit ref="edit" @save-success="getList(-1)"></eocd-exception-edit>
  </div>
</template>

<script>
  import EocdExceptionEdit from "./EocdExceptionEdit";

  export default {
    components: {EocdExceptionEdit},
    data() {
      return {
        loading: false,
        tableData: [
          {
            id: 0,
            reason: "",
            details: "",
            code: "",
          },
        ],
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.getList("eocdException/list").then((res) => {
          this.loading = false;
          this.tableData = JSON.parse(JSON.stringify(res.data));
        }).catch((error) => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError") + error.response.data.message);
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
          this.$http.delete("eocdException", row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });

      },

    },
  };
</script>

<style scoped>
</style>

<template>
  <div>
    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      border
      @row-click="handleRowClick">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <table class="vm-table" style="margin-left: 30px;width: 800px">
            <tr>
              <th style="text-align: center;width: 100px">故障代码</th>
              <th style="text-align: center">故障分析</th>
              <th style="text-align: center;width: 100px">操作</th>
            </tr>
            <tr v-for="(item,index) in scope.row.reasonGroup" :key="item.id">
              <td>
                <el-input v-model="item.faultCode" style="width: 70px;"></el-input>
              </td>
              <td>
                <el-input v-model="item.faultDesc" style="width: 100%;"></el-input>
              </td>
              <td>
                <el-button type="danger" @click="scope.row.reasonGroup.splice(index, 1);">删除</el-button>
              </td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <table-column prop="faultType" label="故障原因">
        <template #default="scope">
          <span v-if="scope.row.faultType === 1">人为原因</span>
          <span v-if="scope.row.faultType === 2">外部原因</span>
          <span v-if="scope.row.faultType === 3">门系统</span>
          <span v-if="scope.row.faultType === 4">曳引系统</span>
          <span v-if="scope.row.faultType === 5">导向系统</span>
          <span v-if="scope.row.faultType === 6">轿厢</span>
          <span v-if="scope.row.faultType === 7">控制系统</span>
          <span v-if="scope.row.faultType === 8">电气系统</span>
          <span v-if="scope.row.faultType === 9">安全保护装置</span>
        </template>
      </table-column>
      <table-column label="操作" align="center" width="200px" :tooltip="false">
        <template #default="scope">
          <el-button type="primary" @click.stop="add(scope.row)">新增</el-button>
          <el-button type="success" @click.stop="handleSubmit(scope.row)">保存设置</el-button>
        </template>
      </table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isExpand: false,
        loading: false,
        tableData: [{
          faultType: "",
          reasonGroup: [{
            faultCode: "",
            faultDesc: "",
          }],
        }],
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.getList("faultReason/list").then((res) => {
          this.loading = false;
          this.tableData = JSON.parse(JSON.stringify(res.data));
        }).catch((error) => {
          this.loading = false;
          this.$message.error("数据获取失败, " + error.response.data.message);
        });
      },
      add(row) {
        let reason = {faultCode: "", faultDesc: ""};
        row.reasonGroup.push(reason);
        this.$refs.dataTable.toggleRowExpansion(row, true);
      },
      handleRowClick(row) {
        this.isExpand = !this.isExpand;
        this.$refs.dataTable.toggleRowExpansion(row, this.isExpand);
      },
      handleSubmit(row) {
        let isNull = false;
        row.reasonGroup.map((item) => {
          if (!item.faultCode || !item.faultDesc) {
            isNull = true;
            this.$message.error("不允许有空值");
          }
        });
        if (!isNull) {
          this.$axios.post("faultReason", row).then(() => {
            this.$message.success("保存成功");
          }).catch((error) => {
            if (error.response) {
              this.$message.error("保存失败, " + error.response.data.message);
            }
          });
        }
      },
    },
  };
</script>

<style scoped>
</style>